//
// SVG Icon
//

.svg-icon {
    @include svg-icon-size(get($svg-icon-sizes, md));
    @include svg-icon-color($text-muted);

    // Theme colors
    @each $name, $color in $theme-text-colors {
        &.svg-icon-#{$name} {
            @include svg-icon-color($color, true);
        }
        &.svg-logo-#{$name} {
            @include svg-logo-color($color, true);
        }
    }

    // Responsive icon sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            // Sizes
            @each $name, $value in $svg-icon-sizes {
                &.svg-icon#{$infix}-#{$name} {
                    @include svg-icon-size($value, true);
                }
            }
        }
    }

    &.svg-icon-flip {
        svg {
            transform: scaleX(-1);
        }
    }
}

path#Path-103, rect#Rectangle, path#Path-104, path#Path-33{
    fill: #3699FF;
}

svg.svg-exportar-size.mr-2{
    width: 19px;
}

.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-primary:focus:not(.btn-text), .btn.btn-light-primary.focus:not(.btn-text){ 
    color: #3699FF !important;
    background-color: #bcddff !important;
}
